<template>
  <div style="font-size: 0.14rem;color: #fff;">
    <div style="padding:0 0.26rem;margin-top: 0.2rem">
      <p>
        <span>最新报警：</span>
        <span style="color: #00F9FF;font-weight: bold">{{ latestAlarm }}</span>
      </p>
      <p style="margin-top: 0.1rem">
        <span>今日报警数：</span>
        <span style="color: #00F9FF;font-weight: bold">{{ statistics.todayAlarmNum }}</span>
        <img src="/static/images/alarm_red.png" style="width:0.29rem;height:0.21rem"/>
        <span style="margin-left: 0.25rem">接警数：</span>
        <span style="color: #00F9FF;font-weight: bold">{{ statistics.todayReadedNum }}</span>
        <span style="margin-left: 0.25rem">平均接警时间：</span>
        <span style="color: #00F9FF;font-weight: bold">{{ statistics.avgReadedTime }}</span>
      </p>
    </div>
    <div style="padding:0 0.26rem;margin-top: 0.2rem">
      <el-row>
        <el-col :span="12">
          <div style=" height: 1.1rem;width: 100%;">
            <bar :data="latestWeekData" title="最近一周警情："></bar>
          </div>
        </el-col>
        <el-col :span="12">
          <p style="text-align: center;color: #00A2FF">最近一周警情分布</p>
          <div style="height:.8rem;width: 100%;">
            <pie :data="distribute" title="最近一周警情分布" ref="pie"></pie>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import format from '@/util/timeFormat.js'

export default {
  components: {
    bar: () => import('@/components/dataPart/echarts/bar'),
    pie: () => import('./common/pie')
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    alarmDetail: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      statistics: {
        latestAlarmTime: 0,
        todayReadedNum: 0,
        todayAlarmNum: 0,
        avgReadedTime: 0,
        latestAlarmSchoolName: ''
      },
      latestWeekData: [],

      distribute: []
    }
  },
  computed: {
    latestAlarm () {
      if (!this.statistics.latestAlarmTime) return ''
      const date = format.formatDate(this.statistics.latestAlarmTime)
      return [date, this.statistics.latestAlarmSchoolName].join(' ')
    }
  },

  watch: {
    data (newData) {
      this.init(newData)
    },

    alarmDetail (newData) {

    }
  },

  mounted () {
    this.init(this.data)
  },

  methods: {
    init (data) {
      for (const i in this.statistics) {
        if (typeof data[i] !== 'undefined') {
          this.statistics[i] = data[i] || 0
        }
        if (i === 'avgReadedTime') {
          this.statistics[i] = format.timeStamp(this.statistics[i])
        }
      }
      const latestWeekData = data.latestWeekData || [0, 0, 0, 0, 0, 0]
      this.latestWeekData = []
      latestWeekData.forEach(item => {
        this.latestWeekData.push({
          name: item.day || '',
          value: item.alarmNum || 0,
          color: '#5969F4'
        })
      })
      // this.$refs.pie.init()
      const distribute = []
      const lastWeekAlarmDistrictSpread = data.lastWeekAlarmDistrictSpread || []
      lastWeekAlarmDistrictSpread.forEach(item => {
        distribute.push({
          name: item.areaName,
          value: item.alarmCount
        })
      })
      this.distribute = distribute
    }
  }
}
</script>
